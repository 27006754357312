<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
<!--      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('店铺.店铺名称')" prop="shopName">
                <a-input v-model="queryParam.shopName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('店铺.店铺名称')"
                         allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>{{ $t('通用.按钮.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon
                  type="redo"/>{{ $t('通用.按钮.重置') }}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl"/>
        </a-modal>
      </div>-->
      <a-button type="primary" @click="loginKf(record, undefined)" v-hasPermi="['shop:shop:add']">
        <a-icon type="plus1" />{{$t('通用.按钮.平台客服')}}
      </a-button>
      <!-- 操作 -->
      <!--      <div class="table-operations">
              <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:shop:add']">
                <a-icon type="plus" />{{$t('通用.按钮.新增')}}
              </a-button>
              <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['shop:shop:edit']">
                <a-icon type="edit" />{{$t('通用.按钮.修改')}}
              </a-button>
              <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['shop:shop:remove']">
                <a-icon type="delete" />{{$t('通用.按钮.删除')}}
              </a-button>
              <table-setting
                :style="{float: 'right'}"
                :table-size.sync="tableSize"
                v-model="columns"
                :refresh-loading="loading"
                @refresh="getList" />
            </div>-->
      <!-- 增加修改 -->
<!--      <create-form
        ref="createForm"
        @ok="getList"
      />-->
      <!-- 数据展示 -->

      <!-- 分页 -->
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageShop, listShop, delShop, updateShopRecommend,ptkf} from '@/api/shop/shop'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {listShopCategory} from "@/api/shop/shopCategory";
import bus from "@/utils/bus";

export default {
  name: 'Shop',
  components: {
    CustomDictTag,
    // CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      categoryList: [{id: "0", categoryName: ''}],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopName: undefined,
        shopLogo: undefined,
        shopBackground: undefined,
        shopKeywords: undefined,
        shopQc: undefined,
        timeOfEntry: undefined,
        shopAreap: undefined,
        shopAreac: undefined,
        shopAreax: undefined,
        shopAddress: undefined,
        longitude: undefined,
        latitude: undefined,
        shopDescription: undefined,
        kfPhone: undefined,
        companyType: undefined,
        settleType: undefined,
        shopType: undefined,
        shopRecommend: undefined,
        userId: undefined,
        categoryId: undefined,
        shopState: undefined,
        shopCloseInfo: undefined,
        shopCollect: undefined,
        attentionNum: undefined,
        shopAccount: undefined,
        settlementAccount: undefined,
        shopCreateTime: undefined,
        shopEndTime: undefined,
        goodsDescribeScore: undefined,
        shopDescribeScore: undefined,
        logisticsScore: undefined,
        shopScore: undefined,
        thatViews: undefined,
        kfAccount: undefined,
        alarmGoodsStock: undefined,
        alarmGoodsSkuStock: undefined,
        weatherBzj: undefined,
        moneyBzj: undefined,
        platformRatio: undefined,
        contactsName: undefined,
        contactsPhone: undefined,
        contactsEmail: undefined,
        contactsCardNo: undefined,
        contactsCardHand: undefined,
        contactsCardFront: undefined,
        contactsCardContrary: undefined,
        businessLicenceNumber: undefined,
        companyName: undefined,
        companyAreap: undefined,
        companyAreac: undefined,
        companyAreax: undefined,
        companyAddressDetail: undefined,
        socialCreditCode: undefined,
        authCertificate: undefined,
        businessSphere: undefined,
        businessLicenceNumberElectronic: undefined,
        bankCardNoType: undefined,
        settlementBankUserName: undefined,
        settlementBankAccountNumber: undefined,
        bankUserPhone: undefined,
        settlementBankAccountName: undefined,
        settlementBankName: undefined,
        settlementBankAddress: undefined,
        accountInstCity: undefined,
        accountInstProvince: undefined,
        trademarkCertificate: undefined,
        shopProtocol: undefined,
        businessLicense: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: this.$t('店铺.店铺名称'),
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('店铺.店铺logo'),
          dataIndex: 'shopLogo',
          ellipsis: true,
          scopedSlots: {customRender: 'shopLogo'},
          align: 'center'
        },
        {
          title: this.$t('店铺.店铺背景图'),
          dataIndex: 'shopBackground',
          ellipsis: true,
          scopedSlots: {customRender: 'shopBackground'},
          align: 'center'
        },
        {
          title: this.$t('店铺.客服电话'),
          dataIndex: 'kfPhone',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('店铺.入驻时长'),
          dataIndex: 'duration',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('店铺.入驻类型'),
          dataIndex: 'settleType',
          scopedSlots: {customRender: 'settleType'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('店铺.是否推荐'),
          dataIndex: 'shopRecommend',
          sorter: 1,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: {customRender: 'shopRecommend'},
          align: 'center'
        },
        {
          title: this.$t('店铺.店铺分类'),
          dataIndex: 'categoryId',
          ellipsis: true,
          scopedSlots: {customRender: 'categoryId'},
          align: 'center'
        },
        {
          title: this.$t('店铺.店铺状态'),
          dataIndex: 'shopState',
          ellipsis: true,
          scopedSlots: {customRender: 'shopStateName'},
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }*/
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getCategoryList();
    /**编辑页面接收到更新的数据 */
    bus.$on('updateShopList',()=>{
      this.getList()
    })
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共') + total + this.$t('通用.文本.条')
    },
    /** 查询店铺列表 */
    getList() {
      this.loading = true
      pageShop(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        shopName: undefined,
        shopLogo: undefined,
        shopBackground: undefined,
        shopKeywords: undefined,
        shopQc: undefined,
        timeOfEntry: undefined,
        shopAreap: undefined,
        shopAreac: undefined,
        shopAreax: undefined,
        shopAddress: undefined,
        longitude: undefined,
        latitude: undefined,
        shopDescription: undefined,
        kfPhone: undefined,
        companyType: undefined,
        settleType: undefined,
        shopType: undefined,
        shopRecommend: undefined,
        userId: undefined,
        categoryId: undefined,
        shopState: undefined,
        shopCloseInfo: undefined,
        shopCollect: undefined,
        attentionNum: undefined,
        shopAccount: undefined,
        settlementAccount: undefined,
        shopCreateTime: undefined,
        shopEndTime: undefined,
        goodsDescribeScore: undefined,
        shopDescribeScore: undefined,
        logisticsScore: undefined,
        shopScore: undefined,
        thatViews: undefined,
        kfAccount: undefined,
        alarmGoodsStock: undefined,
        alarmGoodsSkuStock: undefined,
        weatherBzj: undefined,
        moneyBzj: undefined,
        platformRatio: undefined,
        contactsName: undefined,
        contactsPhone: undefined,
        contactsEmail: undefined,
        contactsCardNo: undefined,
        contactsCardHand: undefined,
        contactsCardFront: undefined,
        contactsCardContrary: undefined,
        businessLicenceNumber: undefined,
        companyName: undefined,
        companyAreap: undefined,
        companyAreac: undefined,
        companyAreax: undefined,
        companyAddressDetail: undefined,
        socialCreditCode: undefined,
        authCertificate: undefined,
        businessSphere: undefined,
        businessLicenceNumberElectronic: undefined,
        bankCardNoType: undefined,
        settlementBankUserName: undefined,
        settlementBankAccountNumber: undefined,
        bankUserPhone: undefined,
        settlementBankAccountName: undefined,
        settlementBankName: undefined,
        settlementBankAddress: undefined,
        accountInstCity: undefined,
        accountInstProvince: undefined,
        trademarkCertificate: undefined,
        shopProtocol: undefined,
        businessLicense: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField === 'createTime') {
          this.queryParam.sortField = 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delShop(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    onShopRecommendChange(checked, record) {
      let data = {id: record.id, shopRecommend: checked}
      updateShopRecommend(data).then(response => {
        if (response.success) {
          this.$message.success(
            this.$t('通用.文本.修改成功'),
            3
          );
          this.$emit('ok')
          record.shopRecommend = checked
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    /*页面初始化时获取分类类别*/
    getCategoryList() {
      listShopCategory().then(response => {
        this.categoryList = response.data;
        let children = this.handleTree(response.data, 'id', 'pid')
        console.log('children', children)
        this.cateOptions = children
      })
    },
    /*根据总后台还是店铺初始化加载页面数据*/
    getColumns() {
      // v-hasPermi="['shop:shop:add']"
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },
    /** 编辑商品信息 **/
    handleUpdate(row) {debugger
      this.$router.push({
        path: 'shopFormShop',
        query:
          {
            id: row.id
          }
      })
    },
    /** 编辑商品信息 **/
    loginKf(row) {
      ptkf().then(response => {
        let openUrl ='http://tencentkf.jing9.fun/dist?id='+response.data.id+''+'&usersig='+response.data.tenCenImSig;
        window.open(openUrl, 'kfWidow',"444");
      })

    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('shop/shop/export',
            ...that.queryParam
            , `店铺_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
